import SharedValidation from './SharedValidation.js';
import { extend } from 'vee-validate';

extend('require-look-up', {
    params: ['hasAtLeastOneLookUpValue', 'rowData'],
    message() {
        return `Must include one of: ISBN, title, author, catalog number, or description`
    },
    validate(value, { hasAtLeastOneLookUpValue, rowData }) {
        return hasAtLeastOneLookUpValue(rowData);
    },    
    computesRequired: true //needed, otherwise this is skipped when empty
});


import { max, email } from 'vee-validate/dist/rules';
export default {
    mixins: [SharedValidation],
    data() {
        return {
            courseRequestHeaderConfiguration: {
                type: 'courserequestsheader',
                label: 'Course Requests Header',
                importModes: [
                    {value:'add', text:'Add New (Skip when course request already present)'},
                    {value:'update', text:'Update (Skip when course request not present, update when found)'},
                    {value:'addAndUpdate', text:'Add and Update'}
                ],
                sampleNotes: [
                    '"Campuses and terms match on abbreviation."',
                    '"Departments and courses match on name."',
                    '"The course must be associated with the deparment"',
                    '"Course requests will match their details based on matching term, department, course, and section."',
                    '"Instructor matches on email, if no match it creates a new instructor from the name and email."'
                    
                ],
                importNotes: () => { return this.importNotes},
                fields: [
                    {
                        key: 'CourseStatus',
                        rules: 'excluded:-1',
                        samples: ['"Open"', '"Completed"', '"Open"']
                    },
                    {
                        key: 'Term',
                        selectOptionsFilter: () => {
                            return 'True';
                        },
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },
                        comparisonProperty: 'name',
                        rules: 'min_value:0|required',
                        dataType: 'terms',
                        samples: ['"2022CMTU"', '"2022GTAC"', '"2018AMTU"']
                    },
                    {
                        key: 'Department',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value, true);
                        },
                        selectOptionsFilter: (row) => {
                            return row.TermId > 0 ? row.TermId : -1;
                        },
                        dataType: 'courseDepartmentsImport',
                        rules: 'min_value:0|required',
                        samples: ['"AI"', '"BIO"', '"CHEM"']
                    },
                    {
                        key: 'Course',
                        selectOptionsFilter: (row) => {
                                return row.DepartmentId > 0 ? row.DepartmentId : -1;
                        },
                        hasErrorCalculator: (value, row) => {
                            if(this.importMode == 'update'){
                                return !this.matchesExistingObject(value, true) || !this.isValidCourseForDepartment(value, row);
                            }else{
                                return this.isEmpty(value);
                            }                            
                        },      
                        dataType:'courses',          
                        rules: 'min_value:0|required',
                        samples: ['"AI1"', '"Biology"', '"101"']
                    },
                    {
                        key: 'Section',//SectionName in the db, matches to section in the UI                        
                        canConvertCase: true,
                        hasErrorCalculator: (value) => {
                            return this.isEmpty(value) || !max.validate(value, { length: 80 });
                        },
                        rules: 'required|max:80',
                        samples: ['"H"', '"A"', '"B"']
                    },
                    {
                        key: 'InstructorName',
                        canConvertCase: true,
                        hasErrorCalculator: (value) => {
                            return this.isEmpty(value) || !max.validate(value, { length: 80 });
                        },

                        rules: 'required|max:80',
                        samples: [
                            '"John"',
                            '"Benning, Jim"',
                            '"Joe Smith"'
                        ]
                    },
                    {
                        key: 'InstructorEmail',
                        hasErrorCalculator: (value) => {
                            return this.isEmpty(value) || !email.validate(value) ||  !max.validate(value, { length: 255 });
                        },

                        rules: 'required|email|max:255',
                        samples: [
                            '"john@myschool.edu"',
                            '"jb@myschool.edu"',
                            '"js@myschool.edu"'
                        ]
                    },
                    {
                        key: 'SectionId',
                        hasErrorCalculator: (value) => {
                            return !this.isEmpty(value) && !max.validate(value, { length: 20 });
                        },

                        rules: 'max:20',
                        samples: ['"123"', '"450"', '"190"']
                    },
                    {
                        key: 'CourseLevel',
                        hasErrorCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },

                        dataType: 'courseLevels',
                        rules: 'min_value:0',
                        samples: ['1', '2', '1']
                    },
                    {
                        key: 'EstimatedEnrollment',
                        hasErrorCalculator: (value) => {
                            return !this.isNonNegativeInteger(value);
                        },
                        convertToNumber: true,
                        rules: 'min_value:0|integer',
                        samples: ['80', '85', '90']
                    },

                    {
                        key: 'ActualEnrollment',
                        hasErrorCalculator: (value) => {
                            return !this.isNonNegativeInteger(value);
                        },
                        convertToNumber: true,
                        rules: 'min_value:0|integer',
                        samples: ['85', '90', '80']
                    },
                    {
                        key: 'WebComment',
                        hasErrorCalculator: (value) => {
                            return !max.validate(value, { length:500 });
                        },

                        rules: 'max:500',
                        samples: [
                            '"Web Comment1"',
                            '"Web Comment2"',
                            '"Web Comment3"'
                        ]
                    },
                    {
                        key: 'FacultyComment',
                        hasErrorCalculator: (value) => {
                            return !max.validate(value, { length:500 });
                        },

                        rules: 'max:500',
                        samples: [
                            '"Faculty Comment1"',
                            '"Faculty Comment2"',
                            '"Faculty Comment3"'
                        ]
                    },
                    {
                        key: 'ShelfTagComment',
                        hasErrorCalculator: (value) => {
                            return !max.validate(value, { length:1000 });
                        },

                        rules: 'max:1000',
                        samples: [
                            '"Tag Comment1"',
                            '"Tag Comment2"',
                            '"Tag Comment3"'
                        ]
                    },
                    {
                        key: 'PrintShelfTag',
                        hasWarningCalculator: value => {
                            return !this.matchesExistingObject(value);
                        },
                        dataType: 'yesNoOptions',
                        warningDefault: true,
                        rules: 'min_value:0',
                        samples: ['"Yes"', '"No"', '"Yes"']
                    }
                ],
                canRunNoParse: true,
                cronJobTypeId: 16
            }
        };
    },
    watch: {
        sameAsAddress: {
            handler: function() {
                this.syncSameAsAddress();
            }
        },
    },

    computed:{
        importNotes(){
            let notes = []
            if(this.newInstructors.length > 0){
                notes.push(`New instructor(s) will be created for the following instructor email(s) not found: ${this.newInstructors.join(', ')}`  )
            }
            if(this.newTermDepartmentAssociations.length > 0){
                notes.push(`New department/term association(s) will be created with 100% allocation percentage for the following: ${this.newTermDepartmentAssociations.join(', ')}`  )
            }

            return notes;
        },

        newInstructors(){
            return [...new Set(this.loadedData.filter(x => 
                !this.selectListOptions.instructors.some(s => s.email === x.InstructorEmail))
                .map(x => x.InstructorEmail))];
        },

        newTermDepartmentAssociations(){
            return this.loadedData.filter(x => 
                !this.selectListOptions.terms.some(t => t.courseDepartmentIds.includes(x.DepartmentId)))
                .map(x => x.Department + '/' + x.Term);

        },
    },
    
    methods: {
        isValidCourseForDepartment(course, row) {
            if(this.isEmpty(course) ||  row.DepartmentId < 1){
                return true;
            }
            return this.selectListOptions.courses.find(x => x.value == course)?.filter == row.DepartmentId
        },

        hasAtLeastOneLookUpValue(row){
            return !(this.isEmpty(row.Isbn)
                && this.isEmpty(row.CatalogNumber)
                && this.isEmpty(row.Title)
                && this.isEmpty(row.Description));
        }

    }
};
