<template>
    <div class="container-fluid content-wrapper">
        <b-row class="content-heading">
            <b-col>
                <div>Imports</div>
            </b-col>
        </b-row>

        <p-card title="Select Import Type">
            <b-row cols="1" cols-lg="2">
                <b-col
                >
                    <p-advanced-select
                        item-text="label"
                        return-object
                        hide-search
                        :options="importConfigurations"
                        v-model="selectedImportConfiguration"
                    >
                    </p-advanced-select
                    >
                </b-col>
            </b-row>
            <b-row cols="1" cols-lg="2">
                <b-col v-if='selectedImportConfiguration && selectedImportConfiguration.importModes'
                >
                    <p-advanced-select
                        label="Import Mode"
                        hide-search
                        default-to-first
                        :options="selectedImportConfiguration.importModes"
                        v-model="importMode"
                        @change="$refs.dataImporter.validateData();"
                    >
                    </p-advanced-select
                    >
                </b-col>
            </b-row>
        </p-card>

        <DataImporter
            ref="dataImporter"
            v-if="selectedImportConfiguration != null"
            :key="selectedImportConfiguration.type"
            :fields="selectedImportConfiguration.fields"
            :data-type="selectedImportConfiguration.type"
            :sample-notes="selectedImportConfiguration.sampleNotes"
            :label="selectedImportConfiguration.label"
            :import-notes-generator="selectedImportConfiguration.importNotes"
            :can-run-no-parse="canRunNoParse"
            :is-job-running-now="isSelectedJobRunning"
            :import-mode="importMode"
            v-model="loadedData"
            @form-reset="dataSaved"
            @run-no-parse="handleRunNoParse"
        />
    </div>
</template>

<script>
import DataImporter from './DataImporter.vue';
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import SupplierConfiguration from './ImportConfigurations.Suppliers.js';
import TermConfiguration from './ImportConfigurations.Terms.js';
import DccConfiguration from './ImportConfigurations.Dccs.js';
import GMConfiguration from './ImportConfigurations.GM.js';
import CourseConfiguration from './ImportConfigurations.Courses.js';
import CourseRequestConfiguration from './ImportConfigurations.CourseRequests.js';
import CourseRequestHeaderConfiguration from './ImportConfigurations.CourseRequestsHeader.js';
import BookConfiguration from './ImportConfigurations.Books.js';
import CustomerConfiguration from './ImportConfigurations.Customers.js';
import CustomerArConfiguration from './ImportConfigurations.CustomerAR.js';
import GiftCardConfiguration from './ImportConfigurations.GiftCards.js';
import TaxRateConfiguration from './ImportConfigurations.TaxRates.js';
import StockOnHandConfiguration from './ImportConfigurations.StockOnHand.js';
import TradeBookConfiguration from './ImportConfigurations.TradeBooks.js';
import ChartOfAccountsConfiguration from './ImportConfigurations.ChartOfAccounts.js';
import axios from 'axios';
import utilityService from '@/services/UtilityService.js';

export default {
    props: {},
    name: 'Import',

    mixins: [
        NavigationGuard,
        StoreListManager,
        SupplierConfiguration,
        TermConfiguration,
        DccConfiguration,
        GMConfiguration,
        CourseConfiguration,
        CourseRequestConfiguration,
        CourseRequestHeaderConfiguration,
        BookConfiguration,
        CustomerConfiguration,
        ChartOfAccountsConfiguration,
        CustomerArConfiguration,
        GiftCardConfiguration,
        TaxRateConfiguration,
        StockOnHandConfiguration,
        TradeBookConfiguration,
        ChartOfAccountsConfiguration
    ],
    data() {
        return {
            loadedData: [],
            selectListOptions: {
                brands: [],
                taxItems: [], //tax type (dcc)
                productTypes: [], //used for dcc type
                priceTagTypes: [],
                packageTypes: [],
                yesNoOptions: [],
                bindings: [],
                states: [],
                suppliers: [],
                textStatus: [],
                dcc: [],
                courses: [],
                campuses: [],
                courseDepartments: [],
                courseLevels: [],
                terms: [],
                sectionNames: [],
                openCompletedOptions: [],
                courseRequestBookStatuses: [],
                instructors: [],
                buybackToSubtractOptions: [],
                supplierReturnPolicies: [],
                shippingProviders: [],
                shippingLevels: [],
                locations: [],
                addReplaceOptions: [],
                webCatalogs: [],
                chartOfAccounts: [],
                chartOfAccountsDescriptions: [],
                chartOfAccountsCategoryDescriptions: []
            },
            selectedImportConfiguration: null,
            importMode: null,
            runningJobs: [],
            isCheckingJobs: false
        };
    },
    components: {
        DataImporter
    },

    watch: {
        loadedData: NavigationGuard.$watcher
    },
    methods: {
        async loadData() {
            await axios.get('/dataexchange/runningjobs')
                .then(resp => {
                    this.runningJobs = resp.data;
                });

            if (this.runningJobs.length) {
                this.isCheckingJobs = true;
                setTimeout(this.checkRunningJobs, 60000);
            }
            this.dataLoaded();
        },
        async checkRunningJobs() {
            if (this.runningJobs.length) {
                let jobLogsQueryParams = this.runningJobs.map((x, index) => `cronJobLogIds[${index}]=${x.cronJobLogId}`).join('&');
                let jobs = await utilityService.checkDataExchangeJobsAsync(jobLogsQueryParams);

                this.runningJobs = jobs.runningJobs;
                if (this.runningJobs.length) {
                    this.isCheckingJobs = true;
                    setTimeout(this.checkRunningJobs, 60000);
                } else {
                    this.isCheckingJobs = false;
                }

                let finishedJobs = jobs.finishedJobs;
                finishedJobs.forEach(job => {
                    if (job.result == 0) {
                        this.$toasted.global
                            .app_error(
                                `The data exchange job '${job.description}' failed. Please review data exchange job logs for details.`
                            );
                    } else if (job.result == 1) {
                        this.$toasted.global
                            .app_success(
                                `The data exchange job '${job.description}' completed successfully. All records were processed.`
                            ).goAway(5000);
                    } else if (job.result == 2) {
                        this.$toasted.global
                            .app_success(
                                `The data exchange job '${job.description}' completed, but not all records were imported. Please review data exchange job logs for more details.`
                            );
                    }
                });
            }
        },
        async handleRunNoParse(file) {
            this.isBusy = true;
            let runNowCommand = {
                source: "Imports",
                description: `${this.selectedImportConfiguration.label} - Manual Run`,
                runDate: new Date(),
                parameters: {
                    action: 'Import',
                    cronJobTypeId: this.selectedImportConfiguration.cronJobTypeId,
                    transportMethod: 4,
                    fileName: file.fileName
                }
            };
            let formData = new FormData();
            formData.append('file', file);
            formData.append(
                'commandString',
                JSON.stringify(runNowCommand)
            );

            let promise = axios.post('dataexchange/runuploadjobnow', formData);
            await promise
                .then(resp => {
                    this.runningJobs = resp.data;
                    this.dataLoaded();
                    if (this.runningJobs.some(x => x.isTriggered)) {
                        this.$toasted.global
                            .app_success(
                                `The Import Without Parsing job has been added to the Data Exchange Job Queue as '${runNowCommand.description}'. Feel free to continue working. A notification will display when the job has finished running.`
                            )
                            .goAway(10000);
                    } else {
                        this.$toasted.global
                            .app_error(
                                "Another data exchange job is of this type is already running. Please wait for the other job finish before starting a new job."
                            );
                    }
                    if (!this.isCheckingJobs) {
                        this.isCheckingJobs = true;
                        setTimeout(this.checkRunningJobs, 60000);
                    }
                })
                .catch(() => {
                    this.dataLoaded();
                    this.$toasted.global
                        .app_error(
                            "There was an error running the import without parsing job. Please contact support if the problem persists."
                        );
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },

    computed: {
        importConfigurations() {
            let configurations = [
                this.chartOfAccountsConfiguration,   
                this.courseRequestHeaderConfiguration,                
                this.courseRequestConfiguration,
                this.courseConfiguration,
                this.customerArConfiguration,
                this.customerConfiguration,
                this.dccConfiguration,
                this.gmConfiguration,
                this.giftCardConfiguration,
                this.stockOnHandConfiguration,
                this.supplierConfiguration
            ];
            if(this.$store.getters.featureFlagEnabled('Tax Rate Import')){
                configurations.push(this.taxRateConfiguration);
            }
            configurations.push(this.termConfiguration);
            configurations.push( this.bookConfiguration);
            configurations.push( this.TradeBookConfiguration);
            return configurations
        },
        isSelectedJobRunning() {
            return this.runningJobs.some(job =>
                job.cronJobTypeId == this.selectedImportConfiguration.cronJobTypeId
            );
        },
        canRunNoParse() {
            return this.selectedImportConfiguration
                && this.selectedImportConfiguration.canRunNoParse == true
                && (!this.selectedImportConfiguration.importModes
                    || this.importMode == "addAndUpdate");
        }
    },

    mounted: async function() {
        await this.loadData();
    }
};
</script>
