import { min_value, max } from 'vee-validate/dist/rules';

export default {
    data() {
        return {
            chartOfAccountsConfiguration: {
                type: 'ChartOfAccounts',
                label: 'Chart of Accounts',
                sampleNotes: [
                    'Add notes here.'
                ],
                fields: [
                    {
                        key: 'LedgerKey',
                        hasErrorCalculator: value => {
                            const [ledgerKeyLocation] = value.split(":"); // LedgerKey format is Location:LedgerNumber
                            const isEmpty = value === '' || value === undefined;
                            const isValidLength = max.validate(value, { length: 384 });
                            const isValid = this.selectListOptions.locations.some(location => location.text?.toLowerCase() === ledgerKeyLocation?.toLowerCase()); // Check to see if ledgerKeyLocation is in the select list of Locations
                            
                            return isEmpty || !isValidLength || !isValid;
                        },
                        rules: 'required|max:384',
                        samples: [
                            '"North Campus:2000"', 
                            '"City Campus:3000"'
                        ],
                    },
                    {
                        key: 'Location',
                        hasErrorCalculator: (value, row) => {
                            const isEmpty = value === '' || value === undefined;
                            const isNew = this.isNewChartOfAccount(row);
                            const isValid = min_value.validate(value, { min: 0 });
                            
                            return (isNew && isEmpty) || (!isNew && !isEmpty && !isValid);
                        },
                        samples: ['"North Campus"', '"City Campus"'],
                        dataType: 'locations'
                    },
                    {
                        key: 'LedgerNumber',
                        label: 'Ledger Number',
                        hasErrorCalculator: (value, row) => {
                            const isEmpty = value === '' || value === undefined;
                            const isNew = this.isNewChartOfAccount(row);
                            const isValidLength = max.validate(value, { length: 255 });
                            
                            return (isNew && isEmpty) || (!isEmpty && !isValidLength);
                        },
                        samples: [
                            '"1000"',
                            '"2000"'
                        ]
                    },                      
                    {
                        key: 'Description',
                        hasErrorCalculator: (value, row) => {
                            const isEmpty = value === '' || value === undefined;
                            const isNew = this.isNewChartOfAccount(row);
                            const isValid = min_value.validate(value, { min: 0 });
                            
                            return (isNew && isEmpty) || (!isNew && !isEmpty && !isValid);
                        },
                        samples: ['"Sales Tax"', '"Pay Out"'],
                        dataType: 'chartOfAccountsDescriptions'
                    },
                    {
                        key: 'Category',
                        hasErrorCalculator: (value, row) => {
                            const isEmpty = value === '' || value === undefined;
                            const isNew = this.isNewChartOfAccount(row);
                            const isValid = min_value.validate(value, { min: 0 });
                            
                            return (isNew && isEmpty) || (!isNew && !isEmpty && !isValid);
                        },
                        samples: ['"Expenses"', '"Other Income"'],
                        dataType: 'chartOfAccountsCategoryDescriptions'
                    },                     
                    {
                        key: 'Reference',
                        hasErrorCalculator: value => {
                            return !max.validate(value, { length: 50 });
                        },
                        rules: 'max:50',
                        samples: [
                            '"Note about this record"', 
                            '"Name changed 1/24/2024"'
                        ],
                    },                    
                ],
                canRunNoParse: true,
                cronJobTypeId: 1
            }
        };
    },
    methods: {
        isNewChartOfAccount(row) {
            return !this.selectListOptions.chartOfAccounts.some(account => account.text.toLowerCase()?.includes(row.LedgerKey.toLowerCase()));
        }
    }
};
